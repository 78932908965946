import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../../assets/images/haystack-meta.png'
import tag from '../../assets/images/tags/haystack.svg'
import Layout from '../../components/Layout'
import { DarkLogoBlock } from '../../components/blocks/DarkLogoBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import MosaicBlock from '../../components/blocks/MosaicBlock'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import Box from '../../components/primitives/Box'
import Row from '../../components/primitives/Row'
import Stack from '../../components/primitives/Stack'
import CTABlock from '../../components/blocks/CTABlock'

export const query = graphql`
  {
    backgroundImage: file(relativePath: { eq: "images/backgrounds/sky.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    mosaicRight: file(
      relativePath: { eq: "images/mosaic/business-outcomes.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 690)
      }
    }
    mosaicLeft: file(
      relativePath: { eq: "images/mosaic/developer-productivity.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 690)
      }
    }
    mosaicRight2: file(
      relativePath: { eq: "images/mosaic/survey-narrow.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight3: file(relativePath: { eq: "images/mosaic/blue-surveys.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft2: file(
      relativePath: { eq: "images/mosaic/blue-investment.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight4: file(
      relativePath: { eq: "images/mosaic/navy-notifications.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
  }
`
const HaystackPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Haystack vs. Swarmia"
      variant="dark"
      isNew
      description="Explore the main differences between Haystack and Swarmia to find out which tool is a better fit for your engineering organization."
      metaImage={MetaImage}
    >
      <HeroBlock
        tag={tag}
        title={<>Gain visibility & improve engineering effectiveness</>}
        content="Haystack and Swarmia appear pretty similar on the surface, but they have a few fundamental differences. Most importantly, while Haystack is a reporting platform, Swarmia is purpose-built to help teams drive change in practice."
        backgroundImage={getImage(data.backgroundImage)!}
        secondaryButton={null}
      />
      <DarkLogoBlock />
      <LeadBlock heading="Swarmia guides you on a path of continuous improvement across three key areas" />
      <MosaicBlock
        title="Drive planned business outcomes"
        content={
          <Stack>
            <Stack space={16}>
              <Box>
                CTOs, VPEs, and engineering directors use Swarmia to make
                decisions that move the business in the right direction.
                <br />
                <br />
              </Box>
            </Stack>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Investment balance
              </Box>
            </Row>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Initiatives
              </Box>
            </Row>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Software capitalization
              </Box>
            </Row>
          </Stack>
        }
        imageAlign="right"
        image={getImage(data.mosaicRight)!}
      />
      <MosaicBlock
        title="Systematically improve developer productivity"
        content={
          <Stack>
            <Stack space={16}>
              <Box>
                Engineering directors and managers use Swarmia to identify and
                eliminate software delivery bottlenecks.
                <br />
                <br />
              </Box>
            </Stack>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Engineering metrics
              </Box>
            </Row>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Work log
              </Box>
            </Row>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Developer overview
              </Box>
            </Row>
          </Stack>
        }
        imageAlign="left"
        image={getImage(data.mosaicLeft)!}
      />
      <MosaicBlock
        title="Create better developer experience"
        content={
          <Stack>
            <Stack space={16}>
              <Box>
                Give your software teams the tools they need to improve the
                experience of shipping software.
                <br />
                <br />
              </Box>
            </Stack>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Working agreements
              </Box>
            </Row>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Slack notifications
              </Box>
            </Row>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                CI insights
              </Box>
            </Row>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Developer experience surveys
              </Box>
            </Row>
          </Stack>
        }
        imageAlign="right"
        image={getImage(data.mosaicRight2)!}
      />
      <QuoteBlock person="vignesh2" />
      <LeadBlock heading="Why leading engineering teams choose Swarmia over Haystack" />
      <MosaicBlock
        title="Improve developer experience with engineering surveys"
        content={
          <>
            Swarmia helps you combine system metrics and developer feedback so
            you know exactly what to improve and how.
            <br />
            <br />
            Correlate engineering metrics with qualitative feedback from your
            developers.
            <br />
            <br />
            Effortlessly run developer surveys and turn the insights you collect
            into concrete action points.
          </>
        }
        image={getImage(data.mosaicRight3)!}
        imageAlign="right"
      />
      <MosaicBlock
        title="Understand and balance engineering investments"
        content={
          <>
            Swarmia’s investment balance provides a high-level overview of how
            your organization and teams divide time between key initiatives,
            projects, and OKRs.
            <br />
            <br />
            The investment categories are fully configurable, allowing you to
            track any type of work you want. After setting basic rules for
            categorizing issues and PRs, Swarmia automatically splits them.
            <br />
            <br />
            Compared to Haystack, Swarmia also offers a lot more leadership
            views.
          </>
        }
        image={getImage(data.mosaicLeft2)!}
        imageAlign="left"
      />
      <MosaicBlock
        title="Use working agreements to improve as a team"
        content={
          <>
            Both Swarmia and Haystack integrate with Slack to send real-time
            notifications. However, Swarmia also lets you set team-wide working
            agreements to align with key priorities.
            <br />
            <br />
            Whether you want to follow industry best practices or set numeric
            targets, Swarmia has a working agreement for that.
          </>
        }
        image={getImage(data.mosaicRight4)!}
        imageAlign="right"
      />
      <QuoteBlock person="alex4" />
      <CTABlock
        title="Swarmia is how the best engineering teams get better. Request a demo today."
        secondaryButton={null}
      />
    </Layout>
  )
}

export default HaystackPage
